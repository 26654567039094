export const members = [
  ["Kip Stromberg, Chairman", "Current Physical Therapy"],
  ["Nancy Lazaro, Vice-Chairman", "Ameri-Title"],
  ["Laurel Adams, Secretary", "Silvercreek Realty Group"],
  ["Linda Youngblood, Treasurer", "Linda Youngblood Insurance"],

  ["Tim Atwood, Director", "Firefighter"],
  ["Jay Edwards, Emeritus", "Retired Business Owner"],
  ["Christina Elliott, Friends of the Hospital Liaison", "Community Member"],
  [
    "Steven Hale, Ex-Officio",
    "Weiser Memorial Hospital - Chief Executive Officer",
  ],
  ["Laurelle Jones, Director", "Wildwood Flowers & Events"],
  ["Chuck Kroll, Director", "Weiser Memorial Hospital Trustee"],
  ["Kara Kunz, Treasurer", "Baker & Murakami Produce Company"],
  ["Michelle Panike, Director", "Select Properties, LLC"],
];
