import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React from "react";
import reading from "../../../../../assets/imgs/portrait/reading.jpg";
import { ExpandMoreRounded } from "@material-ui/icons";
import quality from "../../../../../assets/imgs/backgrounds/qualityassurance.jpg";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  darkContent: {
    textAlign: "left",
    background: theme.palette.primary.main,
    color: "white",
    padding: 5,
    "& li": {
      margin: 5,
    },
  },
}));

export default function AboutFacts(props) {
  const classes = useStyles();
  const pagePanel = useSelector((state) => state.pagePanel);

  return (
    <Container>
      <Accordion
        defaultExpanded={pagePanel === "about-wmh" || pagePanel === "default"}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls={`about-wmh-open`}
          id={"about-wmh"}
        >
          ABOUT WEISER MEMORIAL HOSPITAL (WMH)
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ padding: 10 }}>
            <div>
              <h2 className={"primary-title"} style={{ marginTop: 0 }}>
                Hospital Facilities & Services
              </h2>
            </div>
            <Grid
              container
              spacing={4}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item md={6}>
                <div className={classes.darkContent}>
                  <p>
                    In recent years, the hospital has grown to include the
                    Surgical and Specialty Clinic that provides access to
                    numerous specialists, as well as Family Medical Center, a
                    family practice clinic that provides access to local family
                    physicians.
                  </p>
                  <p>
                    We offer a full spectrum of healthcare services using modern
                    equipment that utilizes the latest in technology. Our
                    competent and professional staff are eager to provide a
                    comfortable and pleasant environment for your visit.
                  </p>
                </div>
              </Grid>
              <Grid item md={6} style={{ textAlign: "center" }}>
                <img
                  style={{ maxWidth: "100%", maxHeight: 300 }}
                  src={reading}
                  alt={"Reading"}
                />
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion defaultExpanded={pagePanel === "history-wmh"}>
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls={`history-wmh-open`}
          id={"history-wmh"}
        >
          WMH HISTORY
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              padding: 10,
              background: "aliceblue",
              margin: "20px auto",
            }}
          >
            <div>
              <h2 className={"primary-title"} style={{ marginTop: 0 }}>
                Hospital History
              </h2>
            </div>
            <Grid
              container
              spacing={4}
              justifyContent={"center"}
              alignItems={"center"}
              wrap={"wrap-reverse"}
              style={{
                width: "initial",
                margin: "initial",
              }}
            >
              <Grid item md={6} style={{ textAlign: "center" }}>
                <img
                  style={{ maxWidth: "100%", maxHeight: 300 }}
                  src={steth}
                  alt={"Stethescope"}
                />
              </Grid>
              <Grid item md={6}>
                <div>
                  <p>
                    <b>
                      WMH is a 25-bed taxing district hospital and has been
                      serving the health care needs of our region since it was
                      established in 1950.
                    </b>{" "}
                    The hospital is a not-for-profit community hospital with an
                    elected 7 member Board of Trustees. In 1991 a Surgical and
                    Specialty Clinic was added onto the facility. In 2000, WMH
                    was awarded designation as a Critical Access Hospital (CAH)
                    and in 2008 a state-of-the-art sleep study lab was added.
                    The clinic now provides Cardiology, Obstetrics and
                    Gynecology, General Surgery, Orthopaedics and a Swing Bed
                    Program (extended stay and rehabilitation).
                  </p>
                  <p>
                    <b>
                      Currently, WMH offers emergency care, acute care services,
                      outpatient services, obstetrics, and orthopedic surgery.{" "}
                    </b>
                    WMH has a staff of 100+ employees, 7 physicians practicing
                    in our outpatient clinics, 1 staff orthopedic surgeon and 5
                    local physicians with hospital privileges.
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion> */}
      <Accordion defaultExpanded={pagePanel === "general-facts"}>
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls={`general-facts-open`}
          id={"general-facts"}
        >
          WMH GENERAL FACTS
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ margin: "20px auto" }}
          >
            <Grid item md={6}>
              <div className={classes.darkContent}>
                <p>
                  <b>
                    Founded in 1950, WMH was established with funding from
                    Washington County, The City of Weiser and individual donors.
                  </b>{" "}
                  We now offer a wide range of health care and related services
                  through the addition of our Surgical and Specialty Clinic. We
                  are now supported in part by the Washington County taxpayers,
                  through the Washington County Hospital Taxing District, with
                  all revenues going back to the hospital’s mission in the form
                  of services, facilities, personnel, and equipment.
                </p>
                <Paper elevation={0}>
                  <ul>
                    <li>
                      <ListItemText>
                        WMH is guided by a volunteer Board of Trustees elected
                        by the Washington County taxpayers.
                      </ListItemText>
                    </li>
                    <li>
                      <ListItemText>
                        WMH serves Washington, Adams and Payette Counties and
                        surrounding areas.
                      </ListItemText>
                    </li>
                    <li>
                      <ListItemText>
                        WMH is a Licensed 25 bed critical access hospital in
                        Weiser.
                      </ListItemText>
                    </li>
                    <li>
                      <ListItemText>
                        The WMH emergency department offers 24 hour urgent care,
                        medical imaging, blood testing and more.
                      </ListItemText>
                    </li>
                    <li>
                      <ListItemText>
                        WMH staff includes more than 100 employees.
                      </ListItemText>
                    </li>
                  </ul>
                </Paper>
              </div>
            </Grid>
            <Grid item md={6}>
              <ul style={{ paddingInlineStart: 0 }}>
                <h2 className={"primary-title"}>In 2023:</h2>
                <Paper className={classes.darkContent}>
                  <li>
                    <ListItemText>
                      WMH performed more than 914 surgical procedures.
                    </ListItemText>
                  </li>
                  <hr />
                  <li>
                    <ListItemText>
                      34 babies were born at WMH last year.
                    </ListItemText>
                  </li>
                  <hr />
                  <li>
                    <ListItemText>
                      228 inpatients were served at WMH.
                    </ListItemText>
                  </li>
                  <hr />
                  <li>
                    <ListItemText>
                      35 Swing Bed patients for recovery, rehabilitation and
                      extended care at the hospital.
                    </ListItemText>
                  </li>
                  <hr />
                  <li>
                    <ListItemText>
                      15,544 outpatients were served at WMH.
                    </ListItemText>
                  </li>
                  <hr />
                  <li>
                    <ListItemText>
                      3,549 visits to the WMH Emergency Department.
                    </ListItemText>
                  </li>
                  <hr />
                  <li>
                    <ListItemText>
                      5,553 patients were seen in Imaging Department.
                    </ListItemText>
                  </li>
                  <hr />
                  <li>
                    <ListItemText>2,033 CT scans.</ListItemText>
                  </li>
                </Paper>
              </ul>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={pagePanel === "hospital-quality"}>
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls={`hospital-quality-open`}
          id={"hospital-quality"}
        >
          HOSPITAL QUALITY
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item md={6}>
              <h2 className={"primary-title"}>Quality Care</h2>
              <h4>
                WMH Awarded{" "}
                <a href={"https://www.iso.org/standard/62085.html"}>
                  ISO 9001:2015
                </a>{" "}
                certification
              </h4>
              <p>
                WMH is an accredited healthcare organization through{" "}
                <a
                  href={
                    "https://www.dnvglhealthcare.com/accreditations/hospital-accreditation"
                  }
                >
                  DNV GL NIAHO Healthcare, Inc
                </a>
                . WMH makes the health of our patients, community, and staff
                priority. We strives for continuous quality to make certain that
                we as an organization are continuing to change and grow with the
                health needs of our community.
              </p>
              <p>
                To practice continuous quality improvement, WMH utilizes ISO
                9001:2015. As a foundation WMH is ISO Certified which requires
                Weiser Memorial Hospital to concentrate on areas like; Customer
                focus, Leadership, Involvement of people, continual improvement,
                and many more just as important areas of healthcare.
              </p>
            </Grid>
            <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
              <img
                src={quality}
                alt={"quality assuarnce"}
                style={{ maxWidth: "100%" }}
              />
            </Grid>
            <Grid item xs={12} className={"dark-content"}>
              <p>
                Healthcare organizations, such as WMH, have been described by
                many as “the most complex systems ever designed by man”. Those
                of us that work in these environments know this statement to be
                true. However, through persistence of our Healthcare Providers,
                leaders and community, Weiser Memorial has met this demand by
                voluntarily committing to get involved with such agencies as the
                Hospital Improvement Innovation Network (HIIN) and{" "}
                <a
                  href={
                    "https://www.hrsa.gov/rural-health/rural-hospitals/mbqip"
                  }
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  Medicare Beneficiary Quality Improvement Project (MBQIP)
                </a>
                .
              </p>
              <p>
                <a
                  href={
                    "https://cha.com/hospital-improvement-innovation-network-hiin/"
                  }
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  HIIN
                </a>{" "}
                is a nationwide effort to reduce preventable hospital acquired
                conditions and readmissions. Incorporation of patients and
                family engagement in health care will be key in achieving the
                goals HIIN puts before us. MBQIP requires Weiser Memorial to
                improve the quality of care provided in critical access
                hospitals (CAHs). This gives Weiser Memorial opportunity to
                improve and provide the highest quality care to each one of our
                patients and their families.
              </p>
              <p>
                Quality Improvement is instrumental in health care, here at WMH
                we are excited to work towards the highest quality care
                possible.
              </p>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
