import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { members } from "./members";

const useStyles = makeStyles((theme) => ({
  panel: {
    maxWidth: 850,
    margin: "0 auto",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
}));

export default function BoardMembers(props) {
  const classes = useStyles();

  return (
    <div className={classes.panel}>
      <blockquote>
        <h2>Our Mission -</h2>
        <h4>
          <i>
            "Linking the community and the hospital to develop the resources to
            deliver high quality healthcare."
          </i>
        </h4>
      </blockquote>
      <div
        style={{ margin: "initial auto", maxWidth: "100%", overflow: "auto" }}
      >
        <h2>
          {new Date().getFullYear() }-{new Date().getFullYear() +1} Board
          Members
        </h2>
        <ul>
          {members
            // .sort((a, b) => (a[0].split(" ")[1] < b[0].split(" ")[1] ? -1 : 1))
            .map((member) => (
              <li key={member[0]}>
                {member[0]} <br />
                <Chip
                  style={{ margin: 2.5, padding: 0 }}
                  color="neutral"
                  label={member[1]}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
